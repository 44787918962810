//Main navbar on scroll
function navbarScrollFunction() {

    var navbar = document.getElementById("navbar-main");

    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
        navbar.classList.remove("navbar-not-scrolled");
        navbar.classList.add("scrolled-navbar");
    } else {
        navbar.classList.add("navbar-not-scrolled");
        navbar.classList.remove("scrolled-navbar");
    }
}
//Blog scroll
function blogScrollFunction() {

    //progress bar code
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

    var percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;        
    var progress_bar = document.getElementById("blog-progressbar");
    progress_bar.style.width = `${percent}%`;
    //navbar code
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 0) {
        document.getElementById("blog-navbar").style.top = "0";
        document.getElementById("navbar-main").style.position = "absolute";
    } else {
        document.getElementById("blog-navbar").style.top = "-300px";
        document.getElementById("navbar-main").style.position = "fixed";

    }
}
function casinoScrollFunction() {

    //to show and unshow navbar
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
        document.getElementById("casino-navbar").style.top = "0";
        document.getElementById("navbar-main").style.position = "absolute";
    } else {
        document.getElementById("casino-navbar").style.top = "-300px";
        document.getElementById("navbar-main").style.position = "fixed";
    }

    //to underline navbar
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    var sections = document.querySelectorAll('*[id^="section-"]');

    sections.forEach(section => {

        if (section.offsetTop <= scrollPosition) {


            //remove all actives from all elements
            var actives = document.querySelector('.casino-middle-menu .active');
            if(actives !== null){
                actives.classList.remove('active');
            }

            var searchID = 'href-' + section.id;
            var href = document.querySelector('[id='+searchID+']').classList.add("active");

        }
    });


}
window.copyUrl = function (url) {
    navigator.clipboard.writeText(url);

    var btn = document.getElementById('copy-link-btn');
    var tooltipID = btn.getAttribute('aria-describedby');
    var tooltip = document.getElementById(tooltipID);
    var inner = tooltip.querySelector('.tooltip-inner').innerHTML = "Gekopieërd";
    // btn.dataset.bsOriginalTitle = "Gekopieërd";

}

window.addEventListener('scroll', navbarScrollFunction);
navbarScrollFunction();

if(document.getElementById("casino-navbar")){
    window.addEventListener('scroll', casinoScrollFunction);
}

if(document.getElementById("blog-navbar")){
    window.addEventListener('scroll', blogScrollFunction);
}


var navtoggle = document.getElementById('navbar-toggler');
navtoggle.addEventListener("click", hamburgerFunc);

function hamburgerFunc() {
    var navbar = document.getElementById("navbar-main");
    navbar.classList.toggle("navbar-is-open");
}