if(document.getElementById('filter-form')){
    setLoadMoreBtn();
    setCornerNumbers();
    checkIfAnyCasinos();
    
    const filterForm = document.getElementById('filter-form');
    filterForm.querySelectorAll('input').forEach(input => {
        input.addEventListener('change', function() {
            checkInputs();
        });
    });
}

function checkInputs(){
    const filters = {};
    
    const filterForm = document.getElementById('filter-form');
    var inputs = filterForm.querySelectorAll('input');

    inputs.forEach(input => {
        if(input.checked == true){

            var id = input.id;
            var group = input.dataset.group;

            if (typeof filters[group] == 'undefined') {
                //bestaat niet
                filters[group] = [];
                filters[group].push(id);
            }else{
                //bestaat al
                filters[group].push(id);
            }
        }
    });

    setFilterUrl(filters);

    let xhttp = new XMLHttpRequest();
    xhttp.open("POST", "/filters", true);
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            const casinos = JSON.parse(xhttp.responseText);
            setFilteredCasinos(casinos);
        }
    };
    xhttp.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send(JSON.stringify({ json: true, filters: filters }));
}


function setFilteredCasinos(casinos){
    var casinocards = document.getElementsByClassName("casinocard");
    var loadMoreBtn = document.getElementById('loadmore-btn');

    for (let card of casinocards) {//hide all cards
        // card.dataset.toomuch = 0;
        card.style.display = "none";
        // console.log(card);
    }

    var i = 1;

    casinos.casinos.forEach(casino => {//show allowed cards
        var element = document.getElementById("casinocard_"+casino.id);

        element.style.display = null;
        i++;
    })

    var i = 1;
    for (let card of casinocards) {//set values for all cards
        if(card.style.display == "none"){
            // card.dataset.toomuch = 1;
            card.dataset.allowed_by_filter = 0;
        }
        
        if(card.style.display !== "none"){
            card.dataset.allowed_by_filter = 1;

            if(i > 5 && loadMoreBtn !== null){//button bestaat en max 5
                card.style.display = "none";
            }
            i++;
        }
    }

    setLoadMoreBtn();
    setCornerNumbers();
    checkIfAnyCasinos();
}

function setFilterUrl(array){
    var filterUrl = "";
    var i = 0;
    for (const [key, value] of Object.entries(array)) {
        
        if(i == 0){
            filterUrl += "?";
        }
        if(i > 0){
            filterUrl += "&";
        }
        filterUrl += key+"="+value;
        i++;
    }
    // alert(filterUrl);
    history.pushState({}, null, "/online-gokken" + filterUrl);
}

function setLoadMoreBtn(){
    var loadMoreBtn = document.getElementById('loadmore-btn');

    if(loadMoreBtn == null){
        return;
    }

    //check shown casinos
    var shownI = 0;
    var allowedI = 0;

    var casinocards = document.getElementsByClassName("casinocard");
    for (let card of casinocards) {
        if(card.dataset.allowed_by_filter == "1" && card.style.display !== "none"){
            shownI++;
        }
        if(card.dataset.allowed_by_filter == "1"){
            allowedI++;
        }
    }

    if(shownI <= 5 && allowedI > 5){
        if(loadMoreBtn.dataset.pressed == "0"){
            loadMoreBtn.style.display = null;
        }
    }else{
        if(loadMoreBtn.dataset.pressed == "0"){
            loadMoreBtn.style.display = "none";
        }
    }
}
function setCornerNumbers(){
    //check shown casinos
    var i = 1;
    var cornernumberDivs = document.getElementsByClassName("corner-number");
    for (let cornernumber of cornernumberDivs) {

        casinocard = cornernumber.parentElement.parentElement.parentElement.parentElement.parentElement;
        if(casinocard.dataset.allowed_by_filter == "1"){
            cornernumber.innerHTML = i;
            i++;
        }
    }

}
function checkIfAnyCasinos(){
    var casinos = document.querySelectorAll('[data-allowed_by_filter="1"]');
    if(casinos.length == 0){
        document.getElementById('no-casinos-found-text').style.display = "";
    }else{
        document.getElementById('no-casinos-found-text').style.display = "none";
    }
}

window.loadMore = function (button) {
    button.dataset.pressed = "1";

    var casinos = document.querySelectorAll('[data-allowed_by_filter="1"]');
    casinos.forEach(casino => {
        casino.style.display = "block";
    });

    // button.style.display = "none";
    button.remove();
}

window.togglePaymentFilters = function () {
    element = document.getElementById('filter-column');

    if(element.classList.contains('d-none')){
        // element.style.display = "block";
        element.classList.remove("d-none");
    }else{
        // element.style.display = "none";
        element.classList.add("d-none");
    }
}

window.loadMoreFilters = function (category) {

    //show inputs
    var inputs = document.querySelectorAll('[data-group="'+category+'"]');
    inputs.forEach(input => {
        var group = input.closest('.form-check');
        group.style.display = "block";
    });

    //remove button
    document.getElementById(category+'-btn').remove();
}
